import React, { useState, useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { Chart, LineElement, PointElement, LineController, CategoryScale, LinearScale } from 'chart.js';
import { Link } from 'react-router-dom';
import './Card.css';

Chart.register(LineElement, PointElement, LineController, CategoryScale, LinearScale);

function Card({ index, path }) {
  const [indicator, setIndicator] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const defaultChartData = (data) => {
    const labels = data.labels || ['', '', '', '', ''];
    const dataset = {
      data: [
        data.dataset[data.dataset.length - 1].value,
        data.dataset[data.dataset.length - 2].value,
        data.dataset[data.dataset.length - 3].value,
        data.dataset[data.dataset.length - 4].value,
        data.dataset[data.dataset.length - 5].value,
      ],
      borderColor: '#5A7DFF',
      backgroundColor: '#5A7DFF',
    };

    return {
      labels: labels,
      datasets: [dataset],
    };
  };

  const defaultChartOptions = () => {
    return {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'top',
        },
      },
      scales: {
        x: {
          title: {
            display: false,
            text: 'Meses',
          },
          ticks: {
            display: false
          },
          grid: {
            display: false,
          },
          border: {
            display: false
          }
        },
        y: {
          title: {
            display: false,
            text: 'Valores',
          },
          ticks: {
            display: false
          },
          grid: {
            display: false
          },
          border: {
            display: false
          }
        },
      },
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let file = await import(`../../data${path}`);
        const chartData = defaultChartData(file.default, 0);
        const chartOptions = defaultChartOptions(file.default);
        console.log(file.default);
        setIndicator(file.default); // Local copy of fetched file
        setChartData(chartData);
        setChartOptions(chartOptions);
      } catch (error) {
        console.error('Error al obtener el archivo JSON:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [])

  if (!isLoading) {
    return (
      <Link className='card' to='/employment/unemployment-rate'>
        <div className='card-header'>
          <p className='card-name'>{indicator.title}</p>
        </div>

        <div className='card-body'>
          <div className='left'>
            <p className='figure'>7,2%</p>
          </div>
          <div className='right'>
            <div className='chart-container'>
              {indicator.type === 'line' ? <><Line data={chartData} options={chartOptions} /></> : ''}
              {indicator.type === 'bar' ? <><Bar data={chartData} options={chartOptions} /></> : ''}
            </div>
          </div>
        </div>
      </Link>
    )
  }
}

export default Card;