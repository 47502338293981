import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SearchSuggestions from '../SearchSuggestions/SearchSuggestions';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import Info from '../Info/Info';
import './Header.css';

function Header({ index, language, handleLanguage, toggleMenuVisibility }) {
  const [showInfo, setShowInfo] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [roundBorder, setRoundBorder] = useState(false);

  const handleInput = (input) => {
    setSearchString(input);
    setRoundBorder(true);
  }

  const handleClose = () => {
    setShowInfo(false);
  }

  return (
    <header>
      <div>
        <div className='logo-container'>
          <div className='burger' onClick={toggleMenuVisibility}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        {showInfo ? <Info language={language} handleClose={handleClose} /> : <></>}

        <div className='searchbox-container'>
          <img className='search-icon' src='../img/icons/search.svg' alt="search" />

          <input type='search' placeholder='Buscar indicadores' className={roundBorder ? 'border' : ''} onInput={(e) => handleInput(e.target.value)} />

          {searchString !== '' ?
            <SearchSuggestions searchString={searchString} index={index} language={language} /> :
            <></>}
        </div>

        <div className='donate'>
          <a className='donate-button'>Donar</a>
        </div>
      </div>

    </header >
  )

}

export default Header;