import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import indexFile from './data/index.json';
import Menu from './components/Menu/Menu';
import Main from './components/Main/Main';
import en from './translations/en.json';
import es from './translations/es.json';
import './styles/global.css';

function App() {
  const [index, setIndex] = useState([]);
  const [language, setLanguage] = useState('en'); // setLanguage(navigator.language.split('-')[0]); // Get Browser default language
  const [isLoading, setIsLoading] = useState(true);
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  useEffect(() => {
    // Get JSON Index File
    setIndex(indexFile);
    setIsLoading(false);

  }, [index]);

  const handleLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  }

  const toggleMenuVisibility = () => {
    setIsMenuVisible(!isMenuVisible);
  }

  if (!isLoading) {
    return (
      <BrowserRouter>
        <div className='App'>
          <Menu index={index} language={language === 'en' ? en : es} isMenuVisible={isMenuVisible} toggleMenuVisibility={toggleMenuVisibility}/>
          <Main index={index} language={language === 'en' ? en : es} handleLanguage={handleLanguage} toggleMenuVisibility={toggleMenuVisibility} />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;