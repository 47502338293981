import React, { useState, useEffect } from "react";
import Card from '../components/Card/Card';
import { Link } from "react-router-dom";

function Overview({ language }) {
  return (
    <div className="overview">
      <div>
        <h1>PIB</h1>
      </div>
      <div className="card-grid">
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
      </div>

      <div>
        <h1>Empleo</h1>
      </div>
      <div className="card-grid">
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
      </div>

      <div>
        <h1>Precios</h1>
      </div>
      <div className="card-grid">
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
      </div>

      <div>
        <h1>Gobierno</h1>
      </div>
      <div className="card-grid">
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
      </div>
    </div>
  )
}

export default Overview;