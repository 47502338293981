import React,{useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import './SearchSuggestions.css';

function SearchSuggestions({ searchString, index, language }) {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={`suggestions ${isOpen ? 'open' : 'closed'}`}>
      {/* {
        index.categories.map((category) => (
          category.indicators.map((indicator) => (
            language[indicator.id].toLowerCase().includes(searchString) ?
            <Link to={indicator.path} onClick={handleClose}>{language[indicator.id]}</Link> : null
          ))
        ))
      } */}
    </div>
  )
}

export default SearchSuggestions;