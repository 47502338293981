import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../Header/Header';
import Overview from '../../pages/Overview';
import IndicatorsByCategory from '../../pages/IndicatorsByCategory';
import './Main.css';
import IndicatorDetails from '../../pages/IndicatorDetails';

function Main({ index, language, handleLanguage, toggleMenuVisibility }) {
  return (
    <div className='main'>
      <Header index={index} language={language} handleLanguage={handleLanguage} toggleMenuVisibility={toggleMenuVisibility} />

      <div className='container'>
        <Routes>
          <Route exact path='/' element={<Overview language={language} />} />

          <Route path='/gdp' element={<IndicatorsByCategory index={index} language={language} />} />
          <Route path='/gdp/:indicator' element={<IndicatorDetails language={language} path={'/'} />} />

          <Route path='/employment' element={<IndicatorsByCategory index={index} language={language} />} />
          <Route path='/employment/:indicator' element={<IndicatorDetails language={language} path={'/'} />} />

          <Route path='/prices' element={<IndicatorsByCategory index={index} language={language} />} />
          <Route path='/prices/:indicator' element={<IndicatorDetails language={language} path={'/'} />} />

          <Route path='/government' element={<IndicatorsByCategory index={index} language={language} />} />
          <Route path='/government/:indicator' element={<IndicatorDetails language={language} path={'/'} />} />

          <Route path='/trade' element={<IndicatorsByCategory index={index} language={language} />} />
          <Route path='/trade/:indicator' element={<IndicatorDetails language={language} path={'/'} />} />

          <Route path='/demography' element={<IndicatorsByCategory index={index} language={language} />} />
          <Route path='/demography/:indicator' element={<IndicatorDetails language={language} path={'/'} />} />

          <Route path='/security' element={<IndicatorsByCategory index={index} language={language} />} />
          <Route path='/security/:indicator' element={<IndicatorDetails language={language} path={'/'} />} />

          <Route path='/health' element={<IndicatorsByCategory index={index} language={language} />} />
          <Route path='/health/:indicator' element={<IndicatorDetails language={language} path={'/'} />} />

          <Route path='/education' element={<IndicatorsByCategory index={index} language={language} />} />
          <Route path='/education/:indicator' element={<IndicatorDetails language={language} path={'/'} />} />

          <Route path='/renewables' element={<IndicatorsByCategory index={index} language={language} />} />
          <Route path='/renewables/:indicator' element={<IndicatorDetails language={language} path={'/'} />} />
        </Routes>
      </div>
    </div>
  )
}

export default Main;