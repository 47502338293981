import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Menu.css';

function Menu({ index, language, isMenuVisible, toggleMenuVisibility }) {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState({});
  const location = useLocation();

  useEffect(() => {
    setCategories(index.categories);
    setIsLoading(false);
  }, [categories]);

  // const isSelected = (categoryPath) => {
  //   return location.pathname === categoryPath;
  // };

  if (!isLoading) {
    return (
      <nav className={`nav-bar ${isMenuVisible ? '' : ' collapsed'}`}>
        <ul className='menu'>
          <p className='namespace'>ECONOMÍA</p>
          <li key='1'>
            <Link to='/gdp'>
              <img src='../img/iconss/coins.svg' alt='' className='icon' />
              <span>PIB</span>
            </Link>
          </li>
          <li key='2'>
            <Link to='/employment'>
              <img src='../img/iconss/briefcase.svg' alt='' className='icon' />
              <span>Empleo</span>
            </Link>
          </li>
          <li key='3'>
            <Link to='/prices'>
              <img src='../img/iconss/wallet.svg' alt='' className='icon' />
              <span>Precios</span>
            </Link>
          </li>
          <li key='4'>
            <Link to='/government'>
              <img src='../img/iconss/government.svg' alt='' className='icon' />
              <span>Gobierno</span>
            </Link>
          </li>
          <li key='5'>
            <Link to='/trade'>
              <img src='../img/iconss/cargo.svg' alt='' className='icon' />
              <span>Comercio</span>
            </Link>
          </li>
          <li key='6'>
            <Link to='/money'>
              <img src='' alt='' className='icon' />
              <span>Dinero</span>
            </Link>
          </li>

          <p className='namespace'>SOCIEDAD</p>
          <li key='7'>
            <Link to='/people'>
              <img src='' alt='' className='icon' />
              <span>Demografía</span>
            </Link>
          </li>

          <li key='8'>
            <Link to='/security'>
              <img src='../img/iconss/badge.svg' alt='' className='icon' />
              <span>Seguridad</span>
            </Link>
          </li>

          <li key='8'>
            <Link to='/health'>
              <img src='../img/iconss/healthcare.svg' alt='' className='icon' />
              <span>Salud</span>
            </Link>
          </li>

          <li key='8'>
            <Link to='/education'>
              <img src='' alt='' className='icon' />
              <span>Educación</span>
            </Link>
          </li>

          <p className='namespace'>AMBIENTE</p>
          <li key='9'>
            <Link to='/environment'>
              <img src='../img/iconss/windmill.svg' alt='' className='icon' />
              <span>Renovables</span>
            </Link>
          </li>

        </ul>
      </nav>
    )
  }

  return null;
}

export default Menu;